import * as loginConstant from "../constants/constant"
import { NotificationStruct } from "../struct/notifications"
import {
  PROFILE_GET_NOTIFICATION,
  PROFILE_GET_TOTAL_NOTIFICATION,
} from "../constants/constant"

const userDetails = {
  profile: {},
  reasons: [],
  userProfile: null,
  loader: true,
  mainImage: null,
  notifications: [NotificationStruct],
  notificationRecords: 0,
}
export default (state = userDetails, action) => {
  switch (action.type) {
    case loginConstant.USER_PROFILE:
      return {
        ...state,
        profile: action.payload,
        loader: false,
      }
    case loginConstant.REASON:
      return {
        ...state,
        reasons: action.payload,
      }
    case loginConstant.GET_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      }

    case loginConstant.LOGOUT:
      return {
        userDetails,
      }

    case loginConstant.GET_IMAGE:
      return {
        ...state,
        mainImage: action.payload,
      }
    case PROFILE_GET_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload,
      }
    case PROFILE_GET_TOTAL_NOTIFICATION:
      return {
        ...state,
        notificationRecords: action.payload,
      }

    default:
      return state
  }
}
