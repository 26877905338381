export const NotificationStruct = {
  IsHiddenName: false,
  IsHiddenDisplayIndex: false,
  NotifyMessageId: 0,
  NotifyMessageName: "",
  TransactionRefNo: "",
  MemberId: 0,
  NofityMessageTypeId: 0,
  Subject: "",
  Message: "",
  IsRead: false,
  NotiChannel: null,
  NotificationId: null,
  MessageMobile: null,
  MemberIdMemberName: "",
  NofityMessageTypeIdNofityMessageTypeName: "",
  NotificationIdNotificationName: null,
  DiffDate: null,
  NofityMessageTypeName: null,
  NotificationDetail: null,
  NotificationTermCondition: null,
  NotificationLink: null,
  NotificationPicture: null,
  PromotionCode: null,
  PromotionId: null,
  ReadMore: false,
  ErrorStateModel: null,
  VersionNumber: "",
  OrganizationId: 0,
  ActiveStatusId: 0,
  DisplayIndex: null,
  CreatedOn: "",
  CreatedOnText: "",
  CreatedOnISO: new Date(),
  CreatedOnTimeText: "",
  CreatedBy: 0,
  ModifiedOn: "",
  ModifiedOnText: "",
  ModifiedOnISO: new Date(),
  ModifiedOnTimeText: "",
  ModifiedBy: 0,
  OrganizationName: null,
  ActiveStatusName: null,
  CreatedBySystemUserName: "",
  ModifiedBySystemUserName: "",
  TokenId: null,
  Language: null,
}
