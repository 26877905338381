// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-en-about-us-jsx": () => import("./../../../src/pages/en/about-us.jsx" /* webpackChunkName: "component---src-pages-en-about-us-jsx" */),
  "component---src-pages-en-blog-jsx": () => import("./../../../src/pages/en/blog.jsx" /* webpackChunkName: "component---src-pages-en-blog-jsx" */),
  "component---src-pages-en-forgot-password-jsx": () => import("./../../../src/pages/en/forgot-password.jsx" /* webpackChunkName: "component---src-pages-en-forgot-password-jsx" */),
  "component---src-pages-en-inbox-jsx": () => import("./../../../src/pages/en/inbox.jsx" /* webpackChunkName: "component---src-pages-en-inbox-jsx" */),
  "component---src-pages-en-login-jsx": () => import("./../../../src/pages/en/login.jsx" /* webpackChunkName: "component---src-pages-en-login-jsx" */),
  "component---src-pages-en-news-js": () => import("./../../../src/pages/en/news.js" /* webpackChunkName: "component---src-pages-en-news-js" */),
  "component---src-pages-en-poli-cancel-jsx": () => import("./../../../src/pages/en/poli-cancel.jsx" /* webpackChunkName: "component---src-pages-en-poli-cancel-jsx" */),
  "component---src-pages-en-poli-fail-jsx": () => import("./../../../src/pages/en/poli-fail.jsx" /* webpackChunkName: "component---src-pages-en-poli-fail-jsx" */),
  "component---src-pages-en-poli-successful-jsx": () => import("./../../../src/pages/en/poli-successful.jsx" /* webpackChunkName: "component---src-pages-en-poli-successful-jsx" */),
  "component---src-pages-en-profile-js": () => import("./../../../src/pages/en/profile.js" /* webpackChunkName: "component---src-pages-en-profile-js" */),
  "component---src-pages-en-register-2-jsx": () => import("./../../../src/pages/en/register2.jsx" /* webpackChunkName: "component---src-pages-en-register-2-jsx" */),
  "component---src-pages-en-register-jsx": () => import("./../../../src/pages/en/register.jsx" /* webpackChunkName: "component---src-pages-en-register-jsx" */),
  "component---src-pages-en-registration-fail-jsx": () => import("./../../../src/pages/en/registration-fail.jsx" /* webpackChunkName: "component---src-pages-en-registration-fail-jsx" */),
  "component---src-pages-en-registration-successful-jsx": () => import("./../../../src/pages/en/registration-successful.jsx" /* webpackChunkName: "component---src-pages-en-registration-successful-jsx" */),
  "component---src-pages-en-reset-password-jsx": () => import("./../../../src/pages/en/reset-password.jsx" /* webpackChunkName: "component---src-pages-en-reset-password-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-reciept-js": () => import("./../../../src/pages/reciept.js" /* webpackChunkName: "component---src-pages-reciept-js" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-pages-th-about-us-jsx": () => import("./../../../src/pages/th/about-us.jsx" /* webpackChunkName: "component---src-pages-th-about-us-jsx" */),
  "component---src-pages-th-blog-jsx": () => import("./../../../src/pages/th/blog.jsx" /* webpackChunkName: "component---src-pages-th-blog-jsx" */),
  "component---src-pages-th-forgot-password-copy-jsx": () => import("./../../../src/pages/th/forgot-password copy.jsx" /* webpackChunkName: "component---src-pages-th-forgot-password-copy-jsx" */),
  "component---src-pages-th-forgot-password-jsx": () => import("./../../../src/pages/th/forgot-password.jsx" /* webpackChunkName: "component---src-pages-th-forgot-password-jsx" */),
  "component---src-pages-th-inbox-jsx": () => import("./../../../src/pages/th/inbox.jsx" /* webpackChunkName: "component---src-pages-th-inbox-jsx" */),
  "component---src-pages-th-login-jsx": () => import("./../../../src/pages/th/login.jsx" /* webpackChunkName: "component---src-pages-th-login-jsx" */),
  "component---src-pages-th-news-js": () => import("./../../../src/pages/th/news.js" /* webpackChunkName: "component---src-pages-th-news-js" */),
  "component---src-pages-th-profile-js": () => import("./../../../src/pages/th/profile.js" /* webpackChunkName: "component---src-pages-th-profile-js" */),
  "component---src-pages-th-register-2-jsx": () => import("./../../../src/pages/th/register2.jsx" /* webpackChunkName: "component---src-pages-th-register-2-jsx" */),
  "component---src-pages-th-register-jsx": () => import("./../../../src/pages/th/register.jsx" /* webpackChunkName: "component---src-pages-th-register-jsx" */),
  "component---src-pages-th-registration-fail-jsx": () => import("./../../../src/pages/th/registration-fail.jsx" /* webpackChunkName: "component---src-pages-th-registration-fail-jsx" */),
  "component---src-pages-th-registration-successful-jsx": () => import("./../../../src/pages/th/registration-successful.jsx" /* webpackChunkName: "component---src-pages-th-registration-successful-jsx" */),
  "component---src-pages-th-reset-password-jsx": () => import("./../../../src/pages/th/reset-password.jsx" /* webpackChunkName: "component---src-pages-th-reset-password-jsx" */),
  "component---src-templates-blog-templates-js": () => import("./../../../src/templates/blogTemplates.js" /* webpackChunkName: "component---src-templates-blog-templates-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/contactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-faqtemplate-js": () => import("./../../../src/templates/faqtemplate.js" /* webpackChunkName: "component---src-templates-faqtemplate-js" */),
  "component---src-templates-inbox-js": () => import("./../../../src/templates/inbox.js" /* webpackChunkName: "component---src-templates-inbox-js" */),
  "component---src-templates-money-transfer-template-js": () => import("./../../../src/templates/moneyTransferTemplate.js" /* webpackChunkName: "component---src-templates-money-transfer-template-js" */),
  "component---src-templates-news-templates-js": () => import("./../../../src/templates/newsTemplates.js" /* webpackChunkName: "component---src-templates-news-templates-js" */),
  "component---src-templates-privacy-template-js": () => import("./../../../src/templates/privacyTemplate.js" /* webpackChunkName: "component---src-templates-privacy-template-js" */),
  "component---src-templates-security-template-js": () => import("./../../../src/templates/securityTemplate.js" /* webpackChunkName: "component---src-templates-security-template-js" */),
  "component---src-templates-terms-template-js": () => import("./../../../src/templates/termsTemplate.js" /* webpackChunkName: "component---src-templates-terms-template-js" */)
}

